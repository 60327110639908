/*Estilos personalizados para la app Mobil Assistance de Copec*/
/*Version del 02-08-2021*/
.bg-menu
{
	background-image: url(../images/menu-izq-fondo.png);
	background-position: top left;
	background-repeat: no-repeat;
	background-color: #0045A2;
	background-size: 100%;
}
.mt-7
{
	margin-top: 200px !important;
}
.content
{
	background-color: #FBFBFB;
}
.logo-copec
{
	height: 89px;
	width: 204px;
	background-image: url(../images/menu-izq-logo-copec.png);
	background-size: 210px;
	background-position: bottom left;
	background-repeat: no-repeat;
	/*+placement: anchor-bottom-left;*/
	position: absolute;
	left: 0;
	bottom: 0;
}
.logo-copec h4
{
	display: none;
}
.form-control-plaintext
{
	font-weight: bold;
	padding: 0px;
	margin-top: -5px;
}
.bg-secondary
{
	background-color: #8B95A2 !important;
}
.card
{
	/*+box-shadow: 0px 2px 8px 2px rgba(164, 164, 164, 0.291);*/
	-moz-box-shadow: 0px 2px 8px 2px rgba(164, 164, 164, 0.291);
	-webkit-box-shadow: 0px 2px 8px 2px rgba(164, 164, 164, 0.291);
	box-shadow: 0px 2px 8px 2px rgba(164, 164, 164, 0.291);
}
.card h5.card-title
{
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 11px;
}
.btn-link:hover
{
	text-decoration: none;
}
.alert-danger a
{
	color: #6A1A21;
	font-weight: 900;
}
.alert .bi-exclamation-triangle-fill
{
	font-size: 30px;
	margin-left: 14px;
}
/*background-image: /*Tabs*/
.nav-tabs .active
{
	background-color: #FBFBFB !important;
	font-weight: bold;
	border-top: 1px solid #CED4DB !important;
	border-left: 1px solid #CED4DB !important;
	border-right: 1px solid #CED4DB !important;
	margin-left: 10px;
	margin-right: 10px;
}
.nav-tabs
{
	border-bottom: 1px solid #CED4DB;
}
/* PASOS  */
ul.stepper
{
	padding: 0 1.5rem;
	padding: 1.5rem;
	margin: 1em -1.5rem;
	overflow-x: hidden;
	overflow-y: auto;
	counter-reset: section;
}
a.circle.label
{
	list-style: none;
}
.stepper-horizontal
{
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.stepper-horizontal li
{
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: .5s;
	transition: 0.5s;
}
ul.stepper li a
{
	padding: 1.5rem;
	text-align: center;
	list-style: none;
}
ul.stepper li a .label
{
	display: inline-block;
	color: #000;
	list-style: none;
}
ul.stepper li a .circle
{
	display: inline-block;
	width: 1.75rem;
	height: 1.75rem;
	margin-right: 0.5rem;
	line-height: 1.7rem;
	color: #000;
	text-align: center;
	background: #E7EAF3;
	border-radius: 50%;
}
ul.stepper li.active a .label
{
	color: #377DFF;
}
ul.stepper li.active a .circle
{
	background-color: #377DFF;
	color: #FFF;
}
ul.stepper li.completed a .label
{
	color: #377DFF;
}
ul.stepper li.completed a .circle
{
	background-color: #DBE7FF;
	color: #377DFF;
}
.stepper-horizontal li:not(:last-child):after
{
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	height: 1px;
	margin: 0.2rem 0 0 0;
	content: "";
	background-color: #E7EAF3;
}
.stepper-horizontal li:not(:first-child):before
{
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	height: 1px;
	margin: 0.2rem 0 0 0;
	content: "";
	background-color: #E7EAF3;
}
/* HOVER PASOS */
.stepper-horizontal li:hover
{
	background-color: rgba(0, 0, 0, 0.06);
}
/* FIN PASOS */
.fon-30
{
	font-size: 30px;
	font-weight: lighter;
}
.rounded-14
{
	border-radius: 14px;
}
th
{
	color: #8B95A2;
}
/*Comprobantes*/
h2.tit-comprobante
{
	text-align: center;
	margin-top: 59px;
	padding-top: 150px;
	background-image: url(../images/icono-muestra-ok.png);
	background-position: top center;
	background-repeat: no-repeat;
	background-size: 100px;
	margin-bottom: 41px;
}
/*Botones*/
button.btn-light
{
	background-color: #EDEFF1;
}
/*Agregar muestra*/
#agregar-muestra-buscador input
{
	padding: 16px 37px 16px 12px;
	/* background-image: url(../images/icono-buscar.png); */
	/* background-position: right center; */
	/* background-repeat: no-repeat; */
	/* background-size: 50px; */
}
.field button{
  position: absolute;
  z-index: 2;
  width: 40px;
  height: 40px;
  top: 8px;
  right: 2px;
  border: 0;
  background-color: transparent;
	background-image: url(../images/icono-buscar.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 40px;
  opacity: 1;
  transition: opacity .3s ease-in-out;
}
.field button:hover{
  opacity: .5;
}
/*Botones nueva muestra y caja*/
#BtnNuevaMuestraCaja a.icono-caja
{
	font-size: 29px;
	display: inline-block;
	/*+placement: 5px 7px;*/
	position: relative;
	left: 5px;
	top: 7px;
	margin-left: 5px;
}
#BtnNuevaMuestraCaja a.icono-caja .badge
{
	font-size: 14px;
	/*+placement: -5px -16px;*/
	position: relative;
	left: -5px;
	top: -16px;
}
/*Solicitud de Analisis Imprimible*/
#solicitud-analisis-print
{
	width: 687px;
	margin-left: auto;
	margin-right: auto;
}
#solicitud-analisis-print .logo
{
	width: 271px;
	margin-bottom: 23px;
}
#solicitud-analisis-print .empresa
{
	margin-bottom: 15px;
}
#solicitud-analisis-print .empresa p
{
	margin-bottom: -1px;
	font-size: 9px;
}
#solicitud-analisis-print .empresa p.total
{
	margin-top: 10px;
}
#solicitud-analisis-print .numero-solicitud
{
	/*+placement: float-right;*/
	float: right;
	position: relative;
	left: inherit;
	top: inherit;
	border: 3px solid #4A5058;
	padding: 31px 45px;
	text-align: center;
	margin-bottom: 27px;
}
#solicitud-analisis-print .numero-solicitud h2
{
	font-weight: bold;
	margin-bottom: 0px;
	font-size: 22px;
	color: #4A5058;
}
#solicitud-analisis-print .numero-solicitud p
{
	margin-bottom: 0px;
	font-weight: bold;
	font-size: 12px;
	color: #4A5058;
}
#solicitud-analisis-print table
{
	font-size: 7px;
	margin-bottom: 16px;
}
#solicitud-analisis-print table th
{
	border-bottom: 1px solid #4A5058;
	color: #4A5058;
	vertical-align: bottom;
}
#solicitud-analisis-print table td
{
	border-bottom: 1px dotted #8F969E;
	vertical-align: top;
}
#solicitud-analisis-print .footer p
{
	font-size: 9px;
}
#solicitud-analisis-print .footer .logo-footer
{
	width: 85px;
}
#solicitud-analisis-print .footer .col-der
{
	text-align: end;
}
/*Caja de muestras*/
#caja-de-muestras .table-responsive table
{
	min-width: 960px;
}
#caja-de-muestras .col-icono img
{
	width: 30px;
}
#caja-de-muestras td.col-icono
{
	width: 12px;
}
/*Caja de muestras comprobante envio*/
#caja-comprobante #aviso .bi-box-seam
{
	font-size: 87px;
	color: #8B95A2;
}
#caja-comprobante #aviso .bi-check-circle-fill
{
	color: #25C9DB;
	font-size: 39px;
	/*+placement: displace -10px 54px;*/
	position: absolute;
	margin-left: -10px;
	margin-top: 54px;
}
/*Modal editar muestra*/
#editar-muestra div.titulo
{
	background-image: url(../images/icono-muestra.svg);
	background-position: center left;
	background-repeat: no-repeat;
	padding-left: 42px;
	margin-bottom: 20px;
	margin-left: -11px;
	margin-top: 9px;
}
#editar-muestra .titulo strong
{
	display: block;
}
#editar-muestra .titulo h3
{
	color: #4A5058;
}
#editar-muestra .card
{
	border: 1px solid #4A5058;
}
/*Modal*/
.modal
{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1060;
	display: none;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;
}
.modal-content{
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #FFF;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
	outline: 0;
}
.modal-body
{
	position: relative;
	flex: 1 1 auto;
	padding: 1rem;
}
.modal-footer
{
	display: flex;
	flex-wrap: wrap;
	flex-shrink: 0;
	align-items: center;
	justify-content: flex-end;
	padding: 0.75rem;
	border-top: 1px none #DEE2E6;
	border-bottom-right-radius: calc(.3rem - 1px);
	border-bottom-left-radius: calc(.3rem - 1px);
}
.modal-dialog
{
	position: relative;
	width: auto;
	margin: 0.5rem;
	pointer-events: none;
}
.modal.fade .modal-dialog
{
	transition: transform 0.3s ease-out;
	transform: translate(0, -50px);
}
@media (prefers-reduced-motion:reduce)
{
	.modal.fade .modal-dialog
	{
		transition: none;
	}
}
.modal.show .modal-dialog
{
	transform: none;
}
.modal.modal-static .modal-dialog
{
	transform: scale(1.02);
}
.modal-dialog-scrollable
{
	height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content
{
	max-height: 100%;
	overflow: hidden;
}
.modal-dialog-scrollable .modal-body
{
	overflow-y: auto;
}
.modal-dialog-centered
{
	display: flex;
	align-items: center;
	min-height: calc(100% - 1rem);
}
.modal-backdrop
{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #000;
}
.modal-backdrop.fade
{
	opacity: 0;
}
.modal-backdrop.show
{
	opacity: 0.5;
}
.modal-header
{
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 1rem;
	border-bottom: 1px none #DEE2E6;
	border-top-left-radius: calc(.3rem - 1px);
	border-top-right-radius: calc(.3rem - 1px);
}
.modal-header .btn-close
{
	padding: 0.5rem 0.5rem;
	margin: -0.5rem -0.5rem -0.5rem auto;
}
.modal-title
{
	margin-bottom: 0;
	line-height: 1.5;
	font-size: 1.15rem;
	color: #4A5058;
}

.modal-footer > *
{
	margin: 0.25rem;
}
@media (min-width: 576px)
{
	.modal-dialog
	{
		max-width: 500px;
		margin: 1.75rem auto;
	}
	.modal-dialog-scrollable
	{
		height: calc(100% - 3.5rem);
	}
	.modal-dialog-centered
	{
		min-height: calc(100% - 3.5rem);
	}
	.modal-sm
	{
		max-width: 300px;
	}
}
@media (min-width: 992px)
{
	.modal-lg, .modal-xl
	{
		max-width: 800px;
	}
}
@media (min-width: 1200px)
{
	.modal-xl
	{
		max-width: 1140px;
	}
}
.modal-fullscreen
{
	width: 100vw;
	max-width: none;
	height: 100%;
	margin: 0;
}
.modal-fullscreen .modal-content
{
	height: 100%;
	border: 0;
	border-radius: 0;
}
.modal-fullscreen .modal-header
{
	border-radius: 0;
}
.modal-fullscreen .modal-body
{
	overflow-y: auto;
}
.modal-fullscreen .modal-footer
{
	border-radius: 0;
}
@media (max-width: 575.98px)
{
	.modal-fullscreen-sm-down
	{
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}
	.modal-fullscreen-sm-down .modal-content
	{
		height: 100%;
		border: 0;
		border-radius: 0;
	}
	.modal-fullscreen-sm-down .modal-header
	{
		border-radius: 0;
	}
	.modal-fullscreen-sm-down .modal-body
	{
		overflow-y: auto;
	}
	.modal-fullscreen-sm-down .modal-footer
	{
		border-radius: 0;
	}
}
@media (max-width: 767.98px)
{
	.modal-fullscreen-md-down
	{
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}
	.modal-fullscreen-md-down .modal-content
	{
		height: 100%;
		border: 0;
		border-radius: 0;
	}
	.modal-fullscreen-md-down .modal-header
	{
		border-radius: 0;
	}
	.modal-fullscreen-md-down .modal-body
	{
		overflow-y: auto;
	}
	.modal-fullscreen-md-down .modal-footer
	{
		border-radius: 0;
	}
}
@media (max-width: 991.98px)
{
	.modal-fullscreen-lg-down
	{
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}
	.modal-fullscreen-lg-down .modal-content
	{
		height: 100%;
		border: 0;
		border-radius: 0;
	}
	.modal-fullscreen-lg-down .modal-header
	{
		border-radius: 0;
	}
	.modal-fullscreen-lg-down .modal-body
	{
		overflow-y: auto;
	}
	.modal-fullscreen-lg-down .modal-footer
	{
		border-radius: 0;
	}
}
@media (max-width: 1199.98px)
{
	.modal-fullscreen-xl-down
	{
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}
	.modal-fullscreen-xl-down .modal-content
	{
		height: 100%;
		border: 0;
		border-radius: 0;
	}
	.modal-fullscreen-xl-down .modal-header
	{
		border-radius: 0;
	}
	.modal-fullscreen-xl-down .modal-body
	{
		overflow-y: auto;
	}
	.modal-fullscreen-xl-down .modal-footer
	{
		border-radius: 0;
	}
}
@media (max-width: 1399.98px)
{
	.modal-fullscreen-xxl-down
	{
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}
	.modal-fullscreen-xxl-down .modal-content
	{
		height: 100%;
		border: 0;
		border-radius: 0;
	}
	.modal-fullscreen-xxl-down .modal-header
	{
		border-radius: 0;
	}
	.modal-fullscreen-xxl-down .modal-body
	{
		overflow-y: auto;
	}
	.modal-fullscreen-xxl-down .modal-footer
	{
		border-radius: 0;
	}
}
/*Colores iconos muestras*/
.normal
{
	color: #95DA92;
}
.precaucion
{
	color: #FEE29E;
}
.alerta
{
	color: #FDA09D;
}
.pendiente
{
	color: #C9CDD3;
}
/*Equipos y componentes*/
#equipos-componentes table.table
{
	min-width: 884px;
}
#equipos-componentes table.table span.icono-equipo
{
	display: block;
	background-position: left top;
	background-repeat: no-repeat;
	color: #535960;
	background-size: 40px;
	padding-left: 47px;
}
#equipos-componentes table.table span.icono-componente
{
	display: block;
	background-position: left top;
	background-repeat: no-repeat;
	color: #535960;
	background-size: 40px;
	padding-left: 47px;
}
/*Modal equipo*/
#modal-equipo .modal-header
{
	border-bottom: 1px none black;
}
#modal-equipo .tipo-codigo-equipo
{
	padding-left: 59px;
	background-position: top left;
	background-repeat: no-repeat;
	background-size: 53px;
	min-height: 53px;
}
#modal-equipo .tipo-codigo-equipo h3, #modal-equipo .tipo-codigo-equipo h4
{
	font-weight: bold;
	margin-bottom: 3px;
	font-size: 20px;
	color: #4C525A;
}
#modal-equipo .datos-equipo td
{
	padding-right: 10px;
}
#modal-equipo .datos-equipo
{
	/*+border-radius: 5px;*/
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	-khtml-border-radius: 5px;
	border-radius: 5px;
	margin-bottom: 27px;
	padding: 18px;
	margin-top: -18px;
	background-color: #EDEDED;
}
#modal-equipo .table-responsive table
{
	min-width: 780px;
}
#modal-equipo .icono-componente
{
	display: block;
	background-position: left top;
	background-repeat: no-repeat;
	color: #535960;
	background-size: 25px;
	padding-left: 33px;
	min-height: 25px;
}
#modal-equipo table a.menu-hamburguesa
{
	/*+placement: float-right;*/
	float: right;
	position: relative;
	left: 0;
	top: 0;
	color: black;
}
/*Imagenes de fondo equipos*/
.camion
{
	background-image: url(../images/icono-camion.svg);
}
.cargador
{
	background-image: url(../images/icono-sin.svg);
}
.perforadora
{
	background-image: url(../images/icono-sin.svg);
}
.grua
{
	background-image: url(../images/icono-sin.svg);
}
.molinodebolas
{
	background-image: url(../images/icono-sin.svg);
}
.boomer
{
	background-image: url(../images/icono-sin.svg);
}
.espesador
{
	background-image: url(../images/icono-sin.svg);
}
.hidrociclon
{
	background-image: url(../images/icono-sin.svg);
}
.roboshot
{
	background-image: url(../images/icono-sin.svg);
}
.tractor
{
	background-image: url(../images/icono-sin.svg);
}
.correatransportadora
{
	background-image: url(../images/icono-sin.svg);
}
.bomba
{
	background-image: url(../images/icono-sin.svg);
}
.trineumatico
{
	background-image: url(../images/icono-sin.svg);
}
.harnero
{
	background-image: url(../images/icono-sin.svg);
}
.agitador
{
	background-image: url(../images/icono-sin.svg);
}
.chancador
{
	background-image: url(../images/icono-sin.svg);
}
.celdaflotacion
{
	background-image: url(../images/icono-sin.svg);
}
.perforadora
{
	background-image: url(../images/icono-sin.svg);
}
.carrodearrastre
{
	background-image: url(../images/icono-sin.svg);
}
.excavadora
{
	background-image: url(../images/icono-sin.svg);
}
.forwarder
{
	background-image: url(../images/icono-sin.svg);
}
.automovil
{
	background-image: url(../images/icono-sin.svg);
}
.rodillo
{
	background-image: url(../images/icono-sin.svg);
}
.torredemadereo
{
	background-image: url(../images/icono-sin.svg);
}
.compresor
{
	background-image: url(../images/icono-sin.svg);
}
.clasificador
{
	background-image: url(../images/icono-sin.svg);
}
/*Imagenes de fondo componentes*/
.mandofinal
{
	background-image: url(../images/icono-sin.svg);
}
/*Modal componente*/
#modal-componente .modal-header
{
	border-bottom: 1px none black;
}
#modal-componente .card h5.card-title
{
	font-size: 21px;
	font-weight: bold;
}
#modal-componente .card table td
{
	padding-right: 18px;
}
#modal-componente #datos-equipo.card
{
	color: #8B95A2;
}
#modal-componente #datos-equipo h5.card-title
{
	color: #8B95A2;
}
#modal-componente img#icono-componente
{
	width: 76px;
	/*+placement: float-right;*/
	float: right;
	position: relative;
	left: 0;
	top: 0;
}
#modal-componente #datos-equipo.card
{
	/*+box-shadow: 0 0;*/
	-moz-box-shadow: 0 0;
	-webkit-box-shadow: 0 0;
	box-shadow: 0 0;
}
/*Modal componente crear*/
#modal-componente-crear #datos-equipo img
{
	width: 63px;
	/*+placement: float-right;*/
	float: right;
	position: relative;
	left: 0;
	top: 0;
}

/*Modal asociar equipo*/
#modal-asociar-equipo table
{
	min-width: 760px;
}
/*Carta autorizaciÃ³n Correos de Chile y Chilexpress*/
#carta-autorizacion
{
	width: 687px;
	margin-left: auto;
	margin-right: auto;
}
#carta-autorizacion img#logo-copec
{
	width: 110px;
}
#carta-autorizacion .izquierda
{
	text-align: right;
}
#carta-autorizacion img#codigo-barra
{
	width: 158px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 37px;
	margin-top: 40px;
}
#carta-autorizacion img#firma
{
	width: 163px;
}
#carta-autorizacion #datos-firma
{
	margin-top: 81px;
}
#carta-autorizacion #datos-firma p
{
	margin-top: -20px;
}
/*Modal equipo asociar componente*/
#modal-equipo-asociar-componente #datos-componente.card
{
	margin-top: 19px;
}
#modal-equipo-asociar-componente #datos-componente.card h5.card-title
{
	font-size: 21px;
	font-weight: bold;
}
#modal-equipo-asociar-componente #datos-componente.card table td
{
	padding-right: 18px;
}
#modal-equipo-asociar-componente #datos-componente img#icono-componente
{
	width: 76px;
	/*+placement: float-right;*/
	float: right;
	position: relative;
	left: 0;
	top: 0;
}
.btn-light-danger
{
	color: #DC3545;
	background-color: #FFE4E7;
	border-color: #FFE4E7;
}
.btn-light-danger:hover
{
	color: #FFE4E7;
	background-color: #DC3545;
	border-color: #DC3545;
}
#solicitudAnali img
{
	width: 30px;
}
#condicionesComerciales th
{
	padding: 4px 1px 4px 0px;
	text-transform: uppercase;
}
#condicionesComerciales td{
	padding: 4px 1px 4px 0px;
}
/*PÃ¡gina de inicio*/
#inicio .bg-btn-inicio{
	background-color: #F2F2F2;
	border-radius: 11px;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	color: #636D7B;
	font-weight: bold;
	border: 0px;
	padding-top: 20px;
	font-size: 14px;
}
#inicio .img-bienvenida{
	max-height: 550px;
}
@media (max-width: 575.98px){
	#inicio .img-bienvenida{
		max-height: 300px;
	}
}


.ds-modal {
  max-height: 90vh;
}
.ds-modal .mat-dialog-container{
  height: auto;
}
.ds-modal-lg .mat-dialog-container{
  padding:0;
}
@media screen and (max-width:576px) {
  .ds-modal-lg{
    max-width: inherit!important;
    width: 100%;
    max-height: 95vh;
    bottom: 0;
    position: fixed!important;
    padding-top: 3.3125rem;
    height: 100%;
  }
  .ds-modal-lg .mat-dialog-container{
    border-radius:0;
  }
}
.ds-submodal{
  width: 90%;
  max-width: 24rem!important;
}
.ds-submodal .mat-dialog-container{
  padding: 0;
}
.ds-submodal .mat-dialog-container .modal__content,
.ds-submodal .mat-dialog-container .modal__header{
  padding: 1.5rem;
}
.ds-submodal .mat-dialog-container .modal__header{
  padding-bottom: 0;
  margin-bottom: 0;
}

@media screen and (max-width:768px) {
  .ds-modal-lg{
    width: 100%;
    max-height: 95vh;
  }
}
@media screen and (min-width:768px) {
  .ds-modal .mat-dialog-container{
    min-width: 30rem;
    max-width: 38rem;
  }
  .ds-modal-lg .mat-dialog-container{
    min-width: 40rem;
    height: initial!important;
  }
}
@media screen and (min-width: 992px) {
    .ds-modal .mat-dialog-container {
        min-width: 30rem;
        max-width: 58rem;
    }
}

.ds-modal--sm .mat-dialog-container{
  min-width: 22rem!important;
  max-width: 28rem!important;
  max-height: 90vh;
}
@media screen and (max-width:567px) {
  .ds-modal--sm .mat-dialog-container{
    min-width: inherit!important;
    max-width: 100%!important;
  }
}
.ds-modal--sm .mat-dialog-container .uploader-wrapper{
  width:100%;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
    border-color: #3682FA;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle {
    color: #3682FA;
    background-color: #3682FA;
}
