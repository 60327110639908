
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$web-primary: mat-palette($mat-indigo);
$web-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$web-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$web-theme: mat-light-theme((
  color: (
    primary: $web-primary,
    accent: $web-accent,
    warn: $web-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($web-theme);

.mat-sort-header.text-center .mat-sort-header-container{
  justify-content: center;
}

.ng-select.ng-select-single.pager .ng-select-container{
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #767776;
  min-width: 60px;
}
.ng-select.ng-select-single.pager .ng-select-container .ng-value-container{
  padding-left: 0;
}
.ng-select.ng-select-single.pager .ng-select-container .ng-value-container .ng-input{
  padding: 0!important;
}

.ng-select.ng-select-single.pager .ng-arrow-wrapper{
  padding-right: 0;
}

.mat-header-cell{
  text-transform: uppercase;
  font-size: 11px;
}

.badge {
  font-weight: 500 !important;

}

.modal.show + .cdk-overlay-container{
  z-index: 2000;
}
.cdk-overlay-container{
  z-index: 1030;
}
.ico--small {
    font-size: 1rem !important;
  }

.chip{
    background-color:#8B95A2;
    color: #fff;
    line-height: 1;
    padding: 4px 12px;
    border-radius: 15px;
    font-size: 12px;
}
